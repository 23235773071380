<template>
  <div class="flex flex-col">
    <FellowShipCard
      v-for="fellowship in fellowships"
      :fellowship="fellowship"
      :key="fellowship.id"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
const FellowShipCard = defineAsyncComponent(() => import('@/components/fellowship/FellowShipCard.vue'))
export default {
  name: 'Fellowship',
  components: {
    FellowShipCard
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('fellowship', ['fellowships'])
  },
  methods: {
    ...mapActions('fellowship', ['fetch']),
    initPage () {
      this.fetch()
    }
  }
}
</script>

<style scoped>

</style>
